import { createTheme, ThemeOptions } from '@material-ui/core';
import { COLORS } from './components/AppGlobalStyle';

const common: ThemeOptions = {
  typography: {
    h1: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 24,
      fontWeight: 400,
    },
    h2: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 22,
      fontWeight: 400,
    },
    h3: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 20,
      fontWeight: 400,
    },
    h4: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 18,
      fontWeight: 400,
    },
    h5: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 16,
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 14,
      fontWeight: 400,
    },
    body1: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 12,
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 12, // Ensure body2 is defined for table cells
      fontWeight: 400,
    },
    subtitle1: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 14, // Slightly larger than body2
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 12, // Same size as body2 but with a distinct purpose
      fontWeight: 400, // Slightly bolder for emphasis
    },
    caption: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 10, // Smaller for supplementary information
      fontWeight: 400,
    },
    button: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 14, // Matches subtitle1 for readability
      fontWeight: 400, // Bold for emphasis on buttons
      textTransform: 'uppercase', // Default Material-UI button style
    },
    overline: {
      fontFamily: 'Roboto Condensed, sans-serif',
      fontSize: 10, // Matches caption size
      fontWeight: 400, // Bold for prominence
      textTransform: 'uppercase', // Consistent with Material-UI convention
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: 6,
        fontSize: 12, // Set font size inside table cells
        fontFamily: 'Roboto Condensed, sans-serif',
      },
    },
    MuiInput: {
      root: {
        fontFamily: 'Roboto Condensed, sans-serif',
      },
    },
    MuiListItemText: {
      root: {
        fontFamily: 'Roboto Condensed, sans-serif',
      },
    },
    MuiCardHeader: {
      title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
      },
    },
  },
};

export const BusinessTheme = createTheme({
  ...common,
  palette: {
    type: 'light',
    primary: {
      light: COLORS.grey,
      main: COLORS.grey,
      dark: COLORS.grey,
    },
    secondary: {
      light: COLORS.green,
      main: COLORS.green,
      dark: COLORS.green,
    },
  },
});

export const InternalTheme = createTheme({
  ...common,
  palette: {
    type: 'light',
    primary: {
      light: COLORS.pink,
      main: COLORS.pink,
      dark: COLORS.pink,
    },
    secondary: {
      light: COLORS.green,
      main: COLORS.green,
      dark: COLORS.green,
    },
  },
});
